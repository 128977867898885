<script lang="ts" setup>
import type { PlacesResponse } from "@/types/api";

const { locale } = useI18n();
const { data: places } = await useAsyncData<PlacesResponse>(locale.value + "_component_places", () =>
  useNuxtApp().$siteFetch("/v1/places", {
    params: {
      "page[size]": 100,
    },
  })
);

import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
const localeRoute = useLocaleRoute();

const pickerModel = computed({
  set: (v) => {
    form.arrival = v.start;
    form.departure = v.end;
  },
  get: () => {
    return {
      start: form.arrival,
      end: form.departure,
    };
  },
});

const form = reactive({
  place: undefined,
  arrival: undefined,
  departure: undefined,
});

const v = useVuelidate(
  {
    place: { required },
    arrival: { required },
    departure: { required },
  },
  form
);

const pending = ref(false);
const onDone = async () => {
  pending.value = true;
  if (!(await v.value.$validate())) {
    pending.value = false;
    return;
  }

  await navigateTo(
    localeRoute({
      name: "place",
      params: {
        place: form.place,
      },
      query: {
        arrival: form.arrival,
        departure: form.departure,
      },
    })
  );
};
</script>

<template>
  <div>
    <p class="mb-2 ml-4 font-semibold">{{ $t("components.search.subtitle") }}</p>
    <h1 class="ml-4 text-xl md:text-5xl font-bold mb-4 md:mb-6">{{ $t("components.search.title") }}</h1>

    <div class="bg-white rounded-3xl text-black p-8 max-w-screen-lg">
      <div class="grid lg:flex gap-4">
        <div class="flex-auto lg:max-w-96">
          <v-select v-model="form.place" item-value="route" :items="places?.data" :label="$t('components.search.place')" />
        </div>
        <div class="flex-auto">
          <RangePicker v-model="pickerModel" :showHours="false" />
        </div>
        <div>
          <v-btn block color="black" size="x-large" :loading="pending" :disabled="pending" class="text-white" variant="elevated" @click="onDone">{{ $t("buttons.search") }}</v-btn>
        </div>
      </div>
    </div>

    <ul class="mt-4 font-medium text-center md:text-left">
      <li class="lg:inline lg:mr-4">✔ {{ $t("components.search.benefit_1") }}</li>
      <li class="lg:inline lg:mr-4">✔ {{ $t("components.search.benefit_2") }}</li>
      <li class="inline">✔ {{ $t("components.search.benefit_3") }}</li>
    </ul>
  </div>
</template>
